import Form from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      formFunctionCode: 'process_monitor_form',
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;

      if (v.field === 'typeId') {
        v.value = '2';
      } else if (v.field === 'listenerEvent') {
        v.options = [
          {
            value: 'create',
            label: '节点创建',
          },
          {
            value: 'complete',
            label: '节点完成',
          },
        ];
        v.value = 'create';
      } else if (v.field === 'listenerType') {
        v.value = 'javaClass';
      }

      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      const eventItem = this.getRule('listenerEvent');
      const typeId = this.getRule('typeId');

      typeId.on.change = (e) => {
        if (e === '1') {
          eventItem.options = [
            {
              value: 'start',
              label: '流程开始',
            },
            {
              value: 'end',
              label: '流程结束',
            },
          ];
          eventItem.value = 'start';
        } else if (e === '2') {
          eventItem.options = [
            {
              value: 'create',
              label: '节点创建',
            },
            {
              value: 'complete',
              label: '节点完成',
            },
          ];
          eventItem.value = 'create';
        }
      };

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .get('/activiti/TaListenerController/query', {
            id: this.formConfig.row.id,
          })
          .then((res) => {
            if (res.success) {
              const data = res.result;
              if (data.listenerEvent === 'start' || data.listenerEvent === 'end') {
                eventItem.options = [
                  {
                    value: 'start',
                    label: '流程开始',
                  },
                  {
                    value: 'end',
                    label: '流程结束',
                  },
                ];
              }
              data.typeId = data.typeId.toString();
              this.setValue(data);
            }
          });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData();

      if (formData) {
        let url = '/activiti/TaListenerController/save';
        const params = { ...formData };

        if (this.formConfig.code === 'edit') {
          url = '/activiti/TaListenerController/update';
          params.id = this.formConfig.row.id;
        } else {
          params.listenerState = 1;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message.success('操作成功');

            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
